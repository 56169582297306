<template>
    <van-tabbar v-model="active" :active-color="group=='product'?'#63C7B7 !important':''">
        <template v-if="group=='article'">
            <!-- <van-tabbar-item icon="home-o" name="home" to="/">
                <span>产品中心</span>
                <template #icon="props">
                    <img :src="require(props.active ?'@/assets/images/activity_active.png':'@/assets/images/activity.png')" />
                </template>
            </van-tabbar-item>         -->
            <van-tabbar-item icon="orders-o" name="leader" to="/leader/list">
                <span>文章</span>
                <template #icon="props">
                    <img :src="require(props.active ?'@/assets/images/article_active.png':'@/assets/images/article.png')" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item icon="user-o" name="my" to="/my/home">
                <span>我的</span>
                <template #icon="props">
                    <img :src="require(props.active ?'@/assets/images/my_active.png':'@/assets/images/my.png')" />
                </template>
            </van-tabbar-item>
        </template>
        <template v-else>
            <van-tabbar-item icon="wap-home-o" name="home" to="/">
                <span>首页</span>
            </van-tabbar-item>     
            <van-tabbar-item icon="user-o" name="my" to="/my/home_product">
                <span>我的</span>
            </van-tabbar-item>
        </template>        
    </van-tabbar>
</template>

<script>
export default {
    props:{
        tabId:{
            type:String
        },
        group:{
            type:String,
            default:'article'
        }
    },
    data(){
        return{
            active:"my"
        }
    },
    mounted(){
        if(this.tabId){
            this.active = this.tabId;
        }
    },
}
</script>

<style>

</style>
