const util = {
    weixinLogin(){
        console.log(location.href,window.btoa(encodeURIComponent(location.href)));
        window.location="/client/wx/jump?go="+window.btoa(encodeURIComponent(location.href));
    },
    isCurrMonth(inDate){
        var nowDate = new Date();
        return ((nowDate.getFullYear() == inDate.getFullYear()) &&  (nowDate.getMonth() == inDate.getMonth()));
    },
    isString(obj){
        return typeof(obj) === "string"? true: false;
    },
    isWeixinBrower(){
        var broser = window.navigator.userAgent.toLowerCase();
        //通过正则表达式匹配broser 中是否含有MicroMessenger字符串
        if (broser.match(/MicroMessenger/i) == 'micromessenger') {
            console.log('微信浏览器');
            // this.weixin();
            return true;
        } else {
            return false;
        }
    }
}

export default util;