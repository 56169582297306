import Axios from 'axios'
import qs from 'qs'
import { Toast } from 'vant';
import store from './../store'


const axiosCreate = Axios.create({
  //设置主要域名
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    "x-requested-with":"XMLHttpRequest"
  },
})

axiosCreate.interceptors.request.use(
  config => {
    if(!config.noloading){
      //loadingInstance = Loading.service({});
    }
    config.headers['Authorization'] = store.state.id +"|"+store.state.open_id;
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
// 拦截器
axiosCreate.interceptors.request.use((config) => {
    if(config.loading!==false){
        Toast.loading({
            duration: 0, // 持续展示 toast
        });
    }

  // 设置头部
  config.withCredentials = true
  if (config.method === 'post') config.data = qs.stringify(config.data);

  return config;
});

// 添加响应拦截器
axiosCreate.interceptors.response.use((response) => {
  if (response.data.status == 'success') {
    Toast.clear();
  }



  // if (response.data.status == NO_LOGIN) {
  //   Toast.fail('登录失效了，请重新登录')
  //   router.replace('/login')
  //   return Promise.reject()
  // }

  
  if (response.data.status == 'fail') {
    Toast.fail(response.data.info);
    return Promise.reject(response.data)
  }

  
  return response.data;
}, (error) => {
    Toast.fail(error.message);
    return Promise.reject(error)
});

export default axiosCreate
