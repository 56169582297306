import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
// import wx from 'weixin-jsapi'
import 'amfe-flexible'
import 'vant/lib/index.css';
Vue.use(Vant);

import ajax from './utils/ajax'
import util from '@/utils/util'
Vue.prototype.$util = util;
Vue.prototype.$ajax = ajax;

import QsNavbar from '@components/qs-nav-bar'
import QsTabbar from '@components/qs-tabbar'
Vue.component('QsNavbar',QsNavbar);
Vue.component('QsTabbar',QsTabbar);

Vue.config.productionTip = false


new Vue({
  router,
  store,
  components:{
    
  },
  render: h => h(App)
}).$mount('#app')
