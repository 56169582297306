import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './../store'
import util from '../utils/util'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
Vue.use(VueRouter)
let initCompleted=false;

const routes = [
  {
    path: '/',
    name: 'Home',
    // redirect:'/product/show?id=30',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/search',
    name: 'Search',
    // redirect:'/category/list',
    component: () => import(/* webpackChunkName: "about" */ '../views/public/search.vue')
  },
  {
    path: '/product/list',
    name: 'productList',
    component: () => import(/* webpackChunkName: "about" */ '../views/product/list.vue')
  },  
  {
    path: '/product/show',
    name: 'productShow',
    component: () => import('../views/product/show.vue')
  },
  {
    path: '/category/show',
    name: 'CategoryShow',
    component: () => import(/* webpackChunkName: "about" */ '@/views/category/show.vue')
  },
  {
    path: '/category/list',
    name: 'CategoryTree',
    component: () => import(/* webpackChunkName: "about" */ '@/views/category/list.vue')
  },
  {
    path: '/leader/list',
    name: 'leaderList',
    component: () => import(/* webpackChunkName: "about" */ '@/views/leader/list.vue')
  },
  {
    path: '/leader/show',
    name: 'leaderShow',
    component: () => import( '@/views/leader/show.vue')
  },
  {
    path: '/public/register',
    name: 'register',
    component: () => import( '@/views/public/register.vue')
  },
  {
    path: '/my/home',
    name: 'myHome',
    component: () => import( '@/views/my/home.vue')
  },
  {
    path: '/my/home_product',
    name: 'myHomeProduct',
    component: () => import( '@/views/my/home_product.vue')
  },  
  {
    path: '/my/home_product_list',
    name: 'myHomeProductList',
    component: () => import( '@/views/my/home_product_list.vue')
  },    
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
      return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if(to.query.token){
    if(!store.getters.isLogin){
      store.commit('updateUser',to.query.token)
    }
  }else{
    if(!store.getters.isLogin){
      let userCookie = VueCookies.get("USERINFO");
      if(initCompleted && userCookie){
        store.commit('updateUser',userCookie)
      }else{
        if(process.env.NODE_ENV != 'development'){
          initCompleted = true;
          // console.log("to:from",to,from,location.href);
          util.weixinLogin();
          // next(false);
          return;
        }
      }
    }
  }

  next();
})
export default router
