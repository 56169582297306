<template>
  <div id="app" ref="app">
    <router-view/>
  </div>
</template>


<script>
import { mapState,mapGetters } from 'vuex'
export default {
  data(){
    return{

    }
  },
  created(){

  },
  mounted(){
    if(!this.isLogin){
      // this.$util.weixinLogin();
    }
  },
  computed:{
    ...mapState({
      user:state=>state.user
    }),
    ...mapGetters({'isLogin':'isLogin'})
  }
}
</script>


<style lang="scss">

@import './assets/css/common.scss';
#app {
  font-family: PingFangSC-Regular, PingFang SC;
  line-height: 21px;
  max-width: 750px;
  margin: 0 auto;
  height:100%;
}

</style>
