<template>
    <van-nav-bar  :title="title" :left-arrow="leftArrow" :left-text="leftText" @click-left="onLeftClick"  border  :fixed="fixed" >
      <template #left>        
        <slot name="left"></slot>
      </template>
      <template #title>        
        <slot name="title"></slot>
      </template>      
      <template #right>        
        <slot name="right"></slot>
      </template>
    </van-nav-bar>
</template>

<script>
import router from '@/router'

export default {
    props:{
        title:{
            type:String,
        },
        leftArrow:{
          type:Boolean,
          default:true
        },
        leftText:{
            type:String,
            default:''
        },
        fixed:{
          type:Boolean,
          default:false
        },
        onLeftClick:{
            type:Function,
            default:()=>{
                router.go(-1);
            }
        }
    }
}
</script>

<style>

</style>
