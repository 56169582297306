import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    id:0,
    open_id:"",
    nickname:'游客',
    subscribe:1,
    full_name:""
  },
  mutations: {
    updateUser(state,data){
      let userInfo = data.split("|");
      state.id=userInfo[0];
      state.subscribe=userInfo[1];
      state.open_id=userInfo[2];
      // alert("updateUser"+state.open_id);
      state.full_name=userInfo[3];
    },
    updateFullName(state,fullName){
      state.full_name = fullName;
    }
  },
  getters:{
    isLogin(state){
      // alert("isLogin"+state.open_id);
      return !!state.open_id;
    },
    isSubscribe(state){
      return state.subscribe>0;
    },
    isRegister(state){
      return !!state.full_name;
    }
  },
  actions: {
  },
  modules: {
  }
})
